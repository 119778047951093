//home
export { default as HomePage } from "./Home/Welcome";

//vote
export { default as Handsomeboy2022Page } from "./vote/2022handsomeboy/2022handsomeboy";
export { default as Highschooluniform2022Page } from "./vote/2022highschooluniform/2022highschooluniform";
export { default as Superpowers2023Page } from "./vote/2023superpowers/2023superpowers";

export { default as Superpowers } from "./vote/2023superpowers/2023superpowers";

export { default as Girl2023 } from "./vote/2023girl/2023girl";
//playground
export { default as RoulettePage } from "./playground/roulette/Roulette";
