import { lazy } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  Handsomeboy2022Page,
  Highschooluniform2022Page,
  Superpowers,
  Girl2023,
} from '../pages';

const GameInfoProvider = lazy(() => import('../store/image-context'));
const UniformGameInfoProvider = lazy(() =>
  import('../store/uniform-image-context')
);
const SuperpowersGameInfoProvider = lazy(() =>
  import('../store/superpower-context')
);
const Girl2023InfoProvider = lazy(() => import('../store/girl-2023-context'));

const VoteRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to='beautyCampaign2023' />} />
      <Route
        path='2022handsomeboy'
        element={
          <GameInfoProvider>
            <Handsomeboy2022Page />
          </GameInfoProvider>
        }
      />

      <Route
        path='2022highschooluniform'
        element={
          <UniformGameInfoProvider>
            <Highschooluniform2022Page />
          </UniformGameInfoProvider>
        }
      />
      <Route
        path='2023superpowers'
        element={
          <SuperpowersGameInfoProvider>
            <Superpowers />
          </SuperpowersGameInfoProvider>
        }
      />
      <Route
        path='beautyCampaign2023'
        element={
          <Girl2023InfoProvider>
            <Girl2023 />
          </Girl2023InfoProvider>
        }
      />
    </Routes>
  );
};
export default VoteRoutes;
