import { SYSTEM_ERROR, UNIFORM_GAME_ID } from "config/CONSTANTS";
import axios from "axios";
import { GET_ALL_CANDIDATES } from "../CONSTANTS";

/**
 * Function to fetch all the Candidates.
 */
export const getAllCandidates = () => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(GET_ALL_CANDIDATES(), {
          gameId: UNIFORM_GAME_ID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getAllCandidates > axios err=", err);
          reject("Error in getAllCandidates axios!");
        });
    } catch (error) {
      console.error("in userServices > getAllCandidates, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
