// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transform: scale(0.4);
}

.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: scale(1);
}
.modal-exit {
  opacity: 0;
  transform: scale(0.4);
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}
@media (max-width: 381px) {
  .title-top {
    padding-top: 18.5rem;
  }
}
@media (max-width: 361px) {
  .title-top {
    padding-top: 19.5rem;
  }
}
@media (max-width: 340px) {
  .title-top {
    padding-top: 20rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/vote/ModalStyles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,QAAQ;EACR,OAAO;EACP,QAAQ,EAAE,+FAA+F;EACzG,oCAAoC;EACpC,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,gCAAgC;EAChC,gBAAgB;EAChB,YAAY;EACZ,UAAU;EACV,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,oBAAoB;EACpB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;AACA;EACE;IACE,oBAAoB;EACtB;AACF;AACA;EACE;IACE,oBAAoB;EACtB;AACF;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".modal {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  right: 0;\n  left: 0;\n  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */\n  background-color: rgba(0, 0, 0, 0.6);\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  transition: all 0.3s ease-in-out;\n  overflow: hidden;\n  z-index: 999;\n  opacity: 0;\n  pointer-events: none;\n  transform: scale(0.4);\n}\n\n.modal-enter-done {\n  opacity: 1;\n  pointer-events: auto;\n  transform: scale(1);\n}\n.modal-exit {\n  opacity: 0;\n  transform: scale(0.4);\n}\n\n.modal-content {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 2rem;\n}\n@media (max-width: 381px) {\n  .title-top {\n    padding-top: 18.5rem;\n  }\n}\n@media (max-width: 361px) {\n  .title-top {\n    padding-top: 19.5rem;\n  }\n}\n@media (max-width: 340px) {\n  .title-top {\n    padding-top: 20rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
