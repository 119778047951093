import { useRef, useEffect, useState } from "react";
import "video.js/dist/video-js.css";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
// import { UniformGameInfoContext } from "store/contex";
// import "videojs-contrib-hls";
// import videojs from "video.js";
// import { isDesktop } from "react-device-detect";



// const VedioStyle = styled.div`
  // background-repeat: repeat;
  // background-position: center top;
  // max-width: 2880px;
  // min-height: 1026px;
  // background-image: url(${(props) => props.backgroundImage});
  // background-size: 50%;
  // padding-top: 20px;
  // .video-list {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  // .video-container {
  //   position: relative;
  //   width: 100%;
  //   padding-top: 56.25%;
  // }

  // .video-container .video-js {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  // }

  // .video-title {
  //   margin-top: 10px;
  //   text-align: center;
  //   font-size: 18px;
  //   font-weight: bold;
  //   cursor: pointer;
  // }
  // .video-item {
  //   width: calc(33.333% - 16px);
  //   margin-right: 16px;
  //   margin-bottom: 16px;
  // }
  // .video-item:nth-child(3n) {
  //   margin-right: 0;
  // }

  // .video-title:hover {
  //   text-decoration: underline;
  // }

  // @media (max-width: 768px) {
  //   .video-item {
  //     flex-wrap: nowrap;
  //   }

  //   .video-item {
  //     width: 100%;
  //     margin-right: 0;
  //   }
  // }
// `;

const VIDEO_LIST = [
  {
    id: 1,
    title: "影音說明文字1",
    url: "https://storage.meteor.today/video/61f6df8af82da432e77f1d08.mp4",
  },
  {
    id: 2,
    title: "影音說明文字2",
    url: "https://storage.cloud.google.com/award-dancing-cats/61961d939714654614a50fdd.MOV",
  },
  {
    id: 3,
    title: "影音說明文字3",
    url: "https://storage.googleapis.com/bat-meteor-video/6141bfb68bc609d646688c36.MP4",
  },
  {
    id: 4,
    title: "影音說明文字4",
    url: "https://storage.googleapis.com/bat-meteor-video/617108d253ea6fb21809c414.MP4",
  },
  {
    id: 5,
    title: "影音說明文字5",
    url: "https://storage.googleapis.com/bat-meteor-video/6188f45aa101e1dca0d1080f.MOV",
  },
  {
    id: 6,
    title: "影音說明文字6",
    url: "https://storage.googleapis.com/bat-meteor-video/6194ff58e88ecb6326ef1619.MOV",
  },
];

export const VideoList = () => {
  const videoRefs = useRef([]);
  const [volume, setVolume] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  useEffect(() => {
    if (isMobile) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              const video = entry.target;
              if (video) {
                const playPromise = video.play();
                if (playPromise !== undefined) {
                  playPromise
                    .then((_) => {
                      video.play();
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              }
            } else {
              const video = entry.target;
              if (video) {
                console.log("pause", video);
                video.pause();
              }
            }
          });
        },
        { threshold: 0.3 }
      );

      videoRefs.current.forEach((ref) => {
        observer.observe(ref);
      });

      return () => observer.disconnect();
    }
  }, [videoRefs.current]);

  const playVideo = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      const playPromise = video.play();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.play();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  const pauseVideo = (index) => {
    const video = videoRefs.current[index];
    if (video) {
      const playPromise = video.pause();
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            video.pause();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };
  const handleVolume = (e, id) => {
    const volume = e.target.value / 100;
    setVolume(volume);

    videoRefs.current.map((data) => {
      data.volume = volume;
    });
    // console.log(videoRefs.current[id].volume);
    // videoRefs.current.volume = volume;
  };
  const togglePlay = () => {
    if (isPlaying) {
      videoRefs.current.pause();
    } else {
      videoRefs.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRefs.current.currentTime);
  };

  const handleDurationChange = () => {
    setDuration(videoRefs.current.duration);
  };

  const handleProgress = () => {
    const progress = (videoRefs.current.currentTime / duration) * 100;
    return progress;
  };
  const renderVideos = () =>
    VIDEO_LIST.map((video, id) => (
      <>
        <div
          key={video.id}
          style={{
            position: "relative",
            width: isMobile ? "100%" : "calc(33.33% - 20px)",
            marginRight: isMobile ? "0" : "20px",
            marginBottom: isMobile ? "20px" : "0",
          }}
        >
          <video
            ref={(ref) => (videoRefs.current[id] = ref)}
            src={video.url}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            autoPlay={!isMobile}
            muted={isMobile}
            loop={!isMobile}
            onTimeUpdate={handleTimeUpdate}
            onLoadedMetadata={handleDurationChange}
            controls
          />
        </div>
      </>
    ));

  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>{renderVideos()}</div>
  );
};

// export const VedioList = ({ videos }) => {
//   const [isMobile, setIsMobile] = useState(false);
//   const videoRefs = useRef([]);

//   useEffect(() => {
//     // 判斷是否為手機版
//     setIsMobile(
//       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//         navigator.userAgent
//       )
//     );
//   }, []);

//   useEffect(() => {
//     // 初始化 videojs
//     videoRefs.current.forEach((videoRef) => {
//       const player = videojs(videoRef, { preload: "auto" });
//       player.poster(player.getAttribute("poster"));
//       player.src({
//         type: "video/mp4",
//         src: player.getAttribute("data-src-mp4"),
//       });
//       player.src({
//         type: "application/x-mpegURL",
//         src: player.getAttribute("data-src-hls"),
//       });

//       // 滑鼠 hover 時自動播放影片
//       player.on("mouseover", () => {
//         if (!isMobile) {
//           player.play();
//         }
//       });
//       player.on("mouseout", () => {
//         if (!isMobile) {
//           player.pause();
//         }
//       });
//     });
//   }, [isMobile]);

//   const onIntersection = (entry, index) => {
//     // 在影片進入畫面時播放影片
//     if (entry.isIntersecting) {
//       const player = videojs(videoRefs.current[index], { preload: "auto" });
//       player.poster(player.getAttribute("poster"));
//       player.src({
//         type: "video/mp4",
//         src: player.getAttribute("data-src-mp4"),
//       });
//       player.src({
//         type: "application/x-mpegURL",
//         src: player.getAttribute("data-src-hls"),
//       });
//       player.play();
//     }
//   };

//   const renderVideos = () =>
//     videos.map((video, index) => (
//       <div
//         key={video.id}
//         style={{
//           position: "relative",
//           width: isMobile ? "100%" : "calc(33.33% - 20px)",
//           height: isMobile ? "calc(100vw * 9 / 16)" : "calc(33.33vw * 9 / 16)",
//           margin: "10px",
//         }}
//       >
//         <video
//           ref={(ref) => (videoRefs.current[index] = ref)}
//           poster={video.poster}
//           data-src-mp4={video.mp4Url}
//           data-src-hls={video.hlsUrl}
//           muted={isMobile ? true : false}
//           playsInline={true}
//           autoPlay={isMobile ? true : false}
//           loop={isMobile ? true : false}
//           controls={isMobile ? true : false}
//           style={{ width: "100%", height: "100%", objectFit: "cover" }}
//         />
//         {isMobile && (
//           <div
//             style={{
//               position: "absolute",
//               top: "0",
//               left: "0",
//               right: "0",
//               bottom: "0",
//               backgroundColor: "rgba(0, 0, 0, 0.6)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <i
//               className="fas fa-play"
//               style={{ color: "#fff", fontSize: "36px" }}
//             />
//           </div>
//         )}
//         {!isMobile && (
//           <div
//             style={{
//               position: "absolute",
//               top: "0",
//               left: "0",
//               right: "0",
//               bottom: "0",
//               backgroundColor: "rgba(0, 0, 0, 0.6)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//               opacity: "0",
//               transition: "opacity 0.3s ease-in-out",
//             }}
//           >
//             <i
//               className="fas fa-play"
//               style={{ color: "#fff", fontSize: "36px" }}
//             />
//           </div>
//         )}
//         {!isMobile && (
//           <IntersectionObserver
//             onChange={(entry) => onIntersection(entry, index)}
//             threshold={0.3}
//           >
//             <div
//               style={{
//                 position: "absolute",
//                 top: "0",
//                 left: "0",
//                 right: "0",
//                 bottom: "0",
//                 cursor: "pointer",
//               }}
//               onMouseOver={() =>
//                 (videoRefs.current[
//                   index
//                 ].parentNode.childNodes[1].style.opacity = "1")
//               }
//               onMouseOut={() =>
//                 (videoRefs.current[
//                   index
//                 ].parentNode.childNodes[1].style.opacity = "0")
//               }
//             />
//           </IntersectionObserver>
//         )}
//         <div
//           style={{
//             position: "absolute",
//             bottom: "10px",
//             left: "10px",
//             color: "#fff",
//             fontSize: "16px",
//           }}
//         >
//           {video.title}
//         </div>
//       </div>
//     ));

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>{renderVideos()}</div>
//   );
// };

// export const VedioList = () => {
//   const videoRefs = useRef([]);
//   const [isMobile, setIsMobile] = useState(false);

//   useEffect(() => {
//     setIsMobile(
//       /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//         navigator.userAgent
//       )
//     );

//     const observer = new IntersectionObserver(
//       (entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             const video = entry.target;
//             console.log(video);
//             if (video) {
//               video.play();
//             }
//           } else {
//             const video = entry.target;
//             if (video) {
//               video.pause();
//             }
//           }
//         });
//       },
//       { threshold: 0.3 }
//     );

//     videoRefs.current.forEach((ref) => {
//       observer.observe(ref);
//     });

//     return () => observer.disconnect();
//   }, [videoRefs.current]);

//   const renderVideos = () =>
//     videos.map((video, index) => (
//       <div
//         key={video.id}
//         style={{
//           position: "relative",
//           width: isMobile ? "100%" : "calc(33.33% - 20px)",
//           marginRight: isMobile ? "0" : "20px",
//           marginBottom: isMobile ? "20px" : "0",
//         }}
//       >
//         <video
//           ref={(ref) => (videoRefs.current[index] = ref)}
//           src={video.url}
//           style={{
//             width: "100%",
//             height: "100%",
//             objectFit: "cover",
//           }}
//         />
//         {!isMobile && (
//           <div
//             style={{
//               position: "absolute",
//               top: "0",
//               left: "0",
//               right: "0",
//               bottom: "0",
//               backgroundColor: "rgba(0, 0, 0, 0.5)",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           ></div>
//         )}
//       </div>
//     ));

//   return (
//     <div style={{ display: "flex", flexWrap: "wrap" }}>{renderVideos()}</div>
//   );
// };

// export const VedioList = () => {
//   const { gameInfo } = useContext(UniformGameInfoContext);
//   const [loadedVideos, setLoadedVideos] = useState([]);
//   const videoRefs = useRef([]);

//   const handleIntersection = (entries) => {
//     entries.forEach((entry) => {
//       if (entry.isIntersecting) {
//         const video = loadedVideos.find(
//           (v) => v.id === parseInt(entry.target.dataset.id)
//         );
//         if (video) {
//           const videoRef = videoRefs.current[video.id - 1];
//           videoRef.src = entry.target.dataset.src;
//           videoRef.load();
//           videoRef.play();
//         }
//       } else {
//         const video = loadedVideos.find(
//           (v) => v.id === parseInt(entry.target.dataset.id)
//         );
//         if (video) {
//           const videoRef = videoRefs.current[video.id - 1];
//           videoRef.pause();
//         }
//       }
//     });
//   };

//   useEffect(() => {
//     const observer = new IntersectionObserver(handleIntersection, {
//       threshold: 0.5,
//     });
//     videoRefs.current = videoRefs.current.slice(0, videos.length);
//     videoRefs.current.forEach((videoRef) =>
//       observer.observe(videoRef.parentElement)
//     );
//     return () => observer.disconnect();
//   }, [loadedVideos]);

//   const handleVideoClick = (event) => {
//     const video = event.currentTarget.querySelector("video");
//     if (video.paused) {
//       video.play();
//     } else {
//       video.pause();
//     }
//   };

//   const handleVideoLoaded = (event, video) => {
//     setLoadedVideos((prev) => [...prev, video]);
//   };

//   // const render = () => (
//   //   <div className={`video-list ${isDesktop ? "desktop" : "mobile"}`}>
//   //     {videos.map((video) => (
//   //       <div
//   //         key={video.id}
//   //         className="video-item"
//   //         style={{ paddingBottom: "56.25%" }}
//   //         onClick={handleVideoClick}
//   //       >
//   //         <video
//   //           ref={(el) => (videoRefs.current[video.id - 1] = el)}
//   //           data-id={video.id}
//   //           data-src={isDesktop ? video.url : video.url}
//   //           onLoadedData={(event) => handleVideoLoaded(event, video)}
//   //         >
//   //           <source
//   //             src={isDesktop ? video.url : video.url}
//   //             type={isDesktop ? "video/mp4" : "application/x-mpegURL"}
//   //           />
//   //         </video>
//   //         <div className="video-title">{video.title}</div>
//   //       </div>
//   //     ))}
//   //   </div>
//   // );

//   return (
//     <VedioStyle
//       backgroundImage={
//         window.innerWidth >= 640
//           ? gameInfo && gameInfo.setPictures.background_1_D
//           : gameInfo && gameInfo.setPictures.background_1_M
//       }
//     >
//       <div className={`video-list ${isDesktop ? "desktop" : "mobile"}`}>
//         {videos.map((video) => (
//           <div
//             key={video.id}
//             className="video-item"
//             style={{ paddingBottom: "56.25%" }}
//             onClick={handleVideoClick}
//           >
//             <video
//               ref={(el) => (videoRefs.current[video.id - 1] = el)}
//               data-id={video.id}
//               data-src={isDesktop ? video.url : video.url}
//               onLoadedData={(event) => handleVideoLoaded(event, video)}
//             >
//               <source
//                 src={isDesktop ? video.url : video.url}
//                 type={isDesktop ? "video/mp4" : "application/x-mpegURL"}
//               />
//             </video>
//             <div className="video-title">{video.title}</div>
//           </div>
//         ))}
//       </div>
//     </VedioStyle>
//   );
// };
