import axios from "axios";
import { SYSTEM_ERROR } from "config/CONSTANTS";
import { VIDEO_UPLOAD } from "../CONSTANTS";

export const videoUploadServices = ({
  cloudFileId,
  uploadToken,
  videoFile,
}) => {
  const headers = {
    "Content-Type": " multipart/form-data",
    "cloud-file-id": cloudFileId,
    "upload-token": uploadToken,
  };
  const formData = new FormData();
  formData.append("video", videoFile);
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(VIDEO_UPLOAD, formData, { headers: headers })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("getGameInfo > axios err=", err);
          reject("Error in getGameInfo axios!");
        });
    } catch (error) {
      console.error("in userServices > getGameInfo, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
