import { SYSTEM_ERROR, SUPERPOWERS_GAME_ID } from "config/CONSTANTS";
import axios from "axios";
import { VOTE } from "../CONSTANTS";

/**
 * Function to vote.
 */
export const vote = (userId, playerId) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(VOTE(), {
          userId: userId,
          playerId: playerId,
          gameId: SUPERPOWERS_GAME_ID,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("vote > axios err=", err);
          reject(err.response.data);
        });
    } catch (error) {
      console.error("in userServices > vote, Err===", error);
      reject(SYSTEM_ERROR);
    }
  });
};
