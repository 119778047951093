// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-checkbox + label .title {
  padding: 0;
  margin-left: 12px;
  cursor: pointer;
}
.css-checkbox {
  display: none;
}
.css-checkbox + label {
  display: flex;
  align-items: flex-start;
}
.css-checkbox + label .squire {
  display: inline-block;
  /* margin-right: 1em; */
  margin-top: 3px;
  /* line-height: 20px; */
  min-width: 15px;
  min-height: 15px;
  /* background: white; */
  border: 1px solid white;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 4px;
}
.css-checkbox:checked + label .squire {
  width: 10px;
  height: 10px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___})
    no-repeat;
  /* background: url(cbx-on.svg) no-repeat; */
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/checkbox.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,eAAe;AACjB;AACA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,qBAAqB;EACrB,uBAAuB;EACvB,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,YAAY;EACZ;aACW;EACX,2CAA2C;AAC7C","sourcesContent":[".css-checkbox + label .title {\n  padding: 0;\n  margin-left: 12px;\n  cursor: pointer;\n}\n.css-checkbox {\n  display: none;\n}\n.css-checkbox + label {\n  display: flex;\n  align-items: flex-start;\n}\n.css-checkbox + label .squire {\n  display: inline-block;\n  /* margin-right: 1em; */\n  margin-top: 3px;\n  /* line-height: 20px; */\n  min-width: 15px;\n  min-height: 15px;\n  /* background: white; */\n  border: 1px solid white;\n  user-select: none;\n  border-radius: 4px;\n}\n.css-checkbox:checked + label .squire {\n  width: 10px;\n  height: 10px;\n  background: url(\"data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBmaWxsPSIjZmZmZmZmIiBkPSJNMTczLjg5OCA0MzkuNDA0bC0xNjYuNC0xNjYuNGMtOS45OTctOS45OTctOS45OTctMjYuMjA2IDAtMzYuMjA0bDM2LjIwMy0zNi4yMDRjOS45OTctOS45OTggMjYuMjA3LTkuOTk4IDM2LjIwNCAwTDE5MiAzMTIuNjkgNDMyLjA5NSA3Mi41OTZjOS45OTctOS45OTcgMjYuMjA3LTkuOTk3IDM2LjIwNCAwbDM2LjIwMyAzNi4yMDRjOS45OTcgOS45OTcgOS45OTcgMjYuMjA2IDAgMzYuMjA0bC0yOTQuNCAyOTQuNDAxYy05Ljk5OCA5Ljk5Ny0yNi4yMDcgOS45OTctMzYuMjA0LS4wMDF6Ii8+PC9zdmc+\")\n    no-repeat;\n  /* background: url(cbx-on.svg) no-repeat; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
