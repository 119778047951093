// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll::-webkit-scrollbar {
  width: 4px;
}
.scroll::-webkit-scrollbar-track {
  /* -webkit-border-radius: 10px; */
  /* border-radius: 10px; */
  margin: 16px 0;
  /* padding-right: 5px; */
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 45px;
  background: #cccccc;
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/scroll.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,iCAAiC;EACjC,yBAAyB;EACzB,cAAc;EACd,wBAAwB;AAC1B;AACA;EAEE,mBAAmB;EACnB,mBAAmB;AACrB","sourcesContent":[".scroll::-webkit-scrollbar {\n  width: 4px;\n}\n.scroll::-webkit-scrollbar-track {\n  /* -webkit-border-radius: 10px; */\n  /* border-radius: 10px; */\n  margin: 16px 0;\n  /* padding-right: 5px; */\n}\n.scroll::-webkit-scrollbar-thumb {\n  -webkit-border-radius: 45px;\n  border-radius: 45px;\n  background: #cccccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
